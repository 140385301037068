@import '../styles/antd-variables.scss';

.claimDetailRoot {
  padding: 0 2rem;
  margin-top: 1rem;
}

.pageTitle {
  font-size: 1.5rem;
  font-weight: bold;
}

.claimInfo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.infoGroupTitle {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.infoKey {
  font-size: 12px;
}

.infoValue {
  margin-bottom: 1rem;
}

.contentBoxLabel {
  font-weight: bold;
  margin-top: 2rem;
}

.contentBox {
  background: $grey-6;
  padding: 2rem;
  margin-bottom: 2rem;
}

.benefitsBox {
  background: $grey-4;
  padding: 2rem;
}

.benefitsBtnWrapper {
  display: flex;
  justify-content: space-between;
}

.step {
}

.stepComplete {
  color: $green-6;
  font-weight: bold;
}

.stepsWrapper {
  line-height: 2;
  margin-bottom: 2rem;
}

.stepBtnWrapper {
  margin-left: 1rem;
  margin-top: .5rem;
  margin-bottom: 1rem;
}

.documentsBox {
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  white-space: nowrap;
  overflow: scroll;
}

.docWrapper {
  display: inline-block;
  margin-right: 2rem;
}

.docImg {
  max-height: 200px;
}

.claimType {
  margin-left: .5rem;
  max-height: 200px;
}

.claimTypeTitle {
  font-weight: bold;
}

.benefitsTitle {
  font-weight: bold;
  margin-top: 2rem;
}

.benefitLink {
  padding: 0;
  display: block;
  margin: 0;
}

.benefitsRow {
  vertical-align: text-top;
}

.extraDocWrap {
  display: flex;
  align-items: baseline;
}

.extraDocDel {
  margin-left: .5rem;
}
