@import '../styles/antd-variables.scss';

.searchWrapper {
  max-width: 800px;
  margin: auto;
  margin-bottom: 2rem;
}

.bodyAndSidebar {
  display: flex;
  padding: 0 2rem;
}

.sidebar {
  border-right: 1px solid $grey-1;
  margin-top: 60px;
  padding-right: 2rem;
  white-space: nowrap;
}

.sidebarTopic:first-child {
  margin-top: 0rem;
}

.sidebarTopic {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.table {
  margin-top: 2rem;
}