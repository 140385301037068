.navBar {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
}

.subNav {
    display: flex;
    align-items: center;
    & > * {
        margin-left: .5rem;
    }
}
