.background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  opacity: .5;
  z-index: 9999;
}

.loading {
  position: fixed;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  display: inline-block;
  background-color: black;
  color: white;
  padding: .5rem .75rem;
  font-size: 12px;
  z-index: 9999;
}