
//--red--------
$red-0: #fff1f0 !default;
$red-1: #ffccc7 !default;
$red-2: #ffa39e !default;
$red-3: #ff7875 !default;
$red-4: #ff4d4f !default;
$red-5: #f5222d !default;
$red-6: #cf1322 !default;
$red-7: #a8071a !default;
$red-8: #820014 !default;
$red-9: #5c0011 !default;

//--volcano--------
$volcano-0: #fff2e8 !default;
$volcano-1: #ffd8bf !default;
$volcano-2: #ffbb96 !default;
$volcano-3: #ff9c6e !default;
$volcano-4: #ff7a45 !default;
$volcano-5: #fa541c !default;
$volcano-6: #d4380d !default;
$volcano-7: #ad2102 !default;
$volcano-8: #871400 !default;
$volcano-9: #610b00 !default;

//--orange--------
$orange-0: #fff7e6 !default;
$orange-1: #ffe7ba !default;
$orange-2: #ffd591 !default;
$orange-3: #ffc069 !default;
$orange-4: #ffa940 !default;
$orange-5: #fa8c16 !default;
$orange-6: #d46b08 !default;
$orange-7: #ad4e00 !default;
$orange-8: #873800 !default;
$orange-9: #612500 !default;

//--gold--------
$gold-0: #fffbe6 !default;
$gold-1: #fff1b8 !default;
$gold-2: #ffe58f !default;
$gold-3: #ffd666 !default;
$gold-4: #ffc53d !default;
$gold-5: #faad14 !default;
$gold-6: #d48806 !default;
$gold-7: #ad6800 !default;
$gold-8: #874d00 !default;
$gold-9: #613400 !default;

//--yellow--------
$yellow-0: #feffe6 !default;
$yellow-1: #ffffb8 !default;
$yellow-2: #fffb8f !default;
$yellow-3: #fff566 !default;
$yellow-4: #ffec3d !default;
$yellow-5: #fadb14 !default;
$yellow-6: #d4b106 !default;
$yellow-7: #ad8b00 !default;
$yellow-8: #876800 !default;
$yellow-9: #614700 !default;

//--lime--------
$lime-0: #fcffe6 !default;
$lime-1: #f4ffb8 !default;
$lime-2: #eaff8f !default;
$lime-3: #d3f261 !default;
$lime-4: #bae637 !default;
$lime-5: #a0d911 !default;
$lime-6: #7cb305 !default;
$lime-7: #5b8c00 !default;
$lime-8: #3f6600 !default;
$lime-9: #254000 !default;

//--green--------
$green-0: #f6ffed !default;
$green-1: #d9f7be !default;
$green-2: #b7eb8f !default;
$green-3: #95de64 !default;
$green-4: #73d13d !default;
$green-5: #52c41a !default;
$green-6: #389e0d !default;
$green-7: #237804 !default;
$green-8: #135200 !default;
$green-9: #092b00 !default;

//--cyan--------
$cyan-0: #e6fffb !default;
$cyan-1: #b5f5ec !default;
$cyan-2: #87e8de !default;
$cyan-3: #5cdbd3 !default;
$cyan-4: #36cfc9 !default;
$cyan-5: #13c2c2 !default;
$cyan-6: #08979c !default;
$cyan-7: #006d75 !default;
$cyan-8: #00474f !default;
$cyan-9: #002329 !default;

//--blue--------
$blue-0: #e6f7ff !default;
$blue-1: #bae7ff !default;
$blue-2: #91d5ff !default;
$blue-3: #69c0ff !default;
$blue-4: #40a9ff !default;
$blue-5: #1890ff !default;
$blue-6: #096dd9 !default;
$blue-7: #0050b3 !default;
$blue-8: #003a8c !default;
$blue-9: #002766 !default;

//--geekblue--------
$geekblue-0: #f0f5ff !default;
$geekblue-1: #d6e4ff !default;
$geekblue-2: #adc6ff !default;
$geekblue-3: #85a5ff !default;
$geekblue-4: #597ef7 !default;
$geekblue-5: #2f54eb !default;
$geekblue-6: #1d39c4 !default;
$geekblue-7: #10239e !default;
$geekblue-8: #061178 !default;
$geekblue-9: #030852 !default;

//--purple--------
$purple-0: #f9f0ff !default;
$purple-1: #efdbff !default;
$purple-2: #d3adf7 !default;
$purple-3: #b37feb !default;
$purple-4: #9254de !default;
$purple-5: #722ed1 !default;
$purple-6: #531dab !default;
$purple-7: #391085 !default;
$purple-8: #22075e !default;
$purple-9: #120338 !default;

//--magenta--------
$magenta-0: #fff0f6 !default;
$magenta-1: #ffd6e7 !default;
$magenta-2: #ffadd2 !default;
$magenta-3: #ff85c0 !default;
$magenta-4: #f759ab !default;
$magenta-5: #eb2f96 !default;
$magenta-6: #c41d7f !default;
$magenta-7: #9e1068 !default;
$magenta-8: #780650 !default;
$magenta-9: #520339 !default;

//--grey--------
$grey-1: #ffffff !default;
$grey-2: #fafafa !default;
$grey-3: #f5f5f5 !default;
$grey-4: #f0f0f0 !default;
$grey-5: #d9d9d9 !default;
$grey-6: #bfbfbf !default;
$grey-7: #8c8c8c !default;
$grey-8: #595959 !default;
$grey-9: #434343 !default;
$grey-10: #262626 !default;
$grey-11: #1f1f1f !default;
$grey-12: #141414 !default;
$grey-13: #000000 !default;