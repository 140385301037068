.formWrapper {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gridWrapper {
  display: inline-grid;
  grid-template-columns: min-content 300px;
}

.inputLabel {
  text-align: left;
  white-space: nowrap;
  margin-right: 1rem;
}

.submitWrapper {
  margin-top: 2rem;
}

.datePicker {
  width: 100%;
}